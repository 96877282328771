import { FC, useCallback, useEffect, useState } from 'react';

import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  Snackbar,
} from '@mui/material';

import { IError } from '@guider-global/shared-types';

import VideoNavBar, { VideoNavBarProps } from './VideoNavBar';
import VideoComposite, { VideoCompositeProps } from './VideoComposite';
import VideoSideBar from './VideoSideBar';

interface VideoRoomProps
  extends VideoNavBarProps,
    Partial<VideoCompositeProps> {
  sessionTitle: string;
  transitionSidebar?: boolean;
  loading?: boolean;
  errors: IError[];
}
const VideoRoom: FC<VideoRoomProps> = ({
  sessionTitle,
  exitSessionLabel,
  relationshipId,
  scheduleSessionLabel,
  goalsLabel,
  chatLabel,
  callAdapter,
  onFetchUserImages,
  localeCode,
  transitionSidebar,
  loading,
  errors,
}) => {
  // Local State
  const [isErrors, setIsErrors] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    const isErrors = errors.length > 0;
    if (isErrors) {
      setIsErrors(true);
    }
  }, [errors.length]);

  const ErrorsAlert = useCallback(() => {
    const clearErrors = () => {
      setIsErrors(false);
    };
    return (
      <Snackbar
        open={isErrors && !loading}
        onClose={clearErrors}
        sx={{ mt: 5 }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Alert onClose={clearErrors} severity="error" sx={{ maxWidth: '100%' }}>
          {[...errors].map((error) => error.message)}
        </Alert>
      </Snackbar>
    );
  }, [errors, isErrors, loading]);

  return (
    <Box
      sx={{
        display: 'grid',
        flexFlow: 'column',
        gridTemplateColumns: '100%',
        gridTemplateRows: '60px 1fr',
        height: '100vh',
        maxHeight: { xs: 'fill-available', md: '100vh' },
      }}
    >
      <Box
        sx={{
          gridColumn: '1',
          gridRow: '1',
        }}
      >
        <VideoNavBar
          sessionTitle={sessionTitle}
          exitSessionLabel={exitSessionLabel}
          relationshipId={relationshipId}
          scheduleSessionLabel={scheduleSessionLabel}
          goalsLabel={goalsLabel}
          chatLabel={chatLabel}
        />
      </Box>
      <Box
        sx={{
          gridColumn: '1',
          gridRow: '2',
          display: 'flex',
          flexFlow: 'row nowrap',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {ErrorsAlert()}
        {!callAdapter || loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <VideoComposite
            callAdapter={callAdapter}
            localeCode={localeCode}
            sessionTitle={sessionTitle}
            onFetchUserImages={onFetchUserImages}
          />
        )}

        <Collapse in={transitionSidebar} orientation="horizontal">
          <VideoSideBar />
        </Collapse>
      </Box>
    </Box>
  );
};

export default VideoRoom;
