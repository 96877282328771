import {
  Box,
  Typography,
  Link,
  BoxProps,
  TypographyProps,
  LinkProps,
} from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export interface TypographyActionProps {
  actionLabel: string;
  onClick: () => void;
  containerProps?: BoxProps;
  typographyProps?: TypographyProps<'h4'>;
  linkProps?: LinkProps<'button'>;
  'data-cy': string;
}

export const TypographyAction: FC<PropsWithChildren<TypographyActionProps>> = ({
  children,
  actionLabel,
  onClick,
  containerProps,
  typographyProps,
  linkProps,
  'data-cy': dataCy,
}) => {
  return (
    <Box
      data-cy={`${dataCy}-container`}
      sx={{
        alignSelf: 'center',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}
      {...containerProps}
    >
      {/* // TODO COMPONENT Policy link */}
      {children ? (
        <Typography
          variant="body1"
          sx={{ alignSelf: 'center' }}
          {...typographyProps}
          component="h4"
          data-cy={`${dataCy}-header`}
        >
          {children}
        </Typography>
      ) : null}
      <Link
        underline="hover"
        color="info.main"
        variant="body1"
        onClick={onClick}
        {...linkProps}
        component="button"
        data-cy={`${dataCy}-action-link`}
      >
        {actionLabel}
      </Link>
    </Box>
  );
};
