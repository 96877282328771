import { FC } from 'react';
import { AiTwotoneCalendar, AiFillFlag } from 'react-icons/ai';
import { BsFillChatLeftTextFill } from 'react-icons/bs';
import { Box } from '@mui/material';

import { useAppDispatch } from 'store/hooks';
import {
  setTransitionSidebar,
  setSidebar,
  SidebarType,
} from 'store/slices/videoRoomSlice';

import NavBarButton from '../NavBarButton';
import { useMixpanelEvents } from 'hooks';

interface InCallNavBarButtonsProps {
  scheduleSessionLabel: string;
  goalsLabel: string;
  chatLabel: string;
}

const InCallNavBarButtons: FC<InCallNavBarButtonsProps> = ({
  scheduleSessionLabel,
  goalsLabel,
  chatLabel,
}) => {
  const { trackClick } = useMixpanelEvents();
  // Redux
  const dispatch = useAppDispatch();

  const handleSidebar = (sidebar: SidebarType) => {
    dispatch(setTransitionSidebar(true));
    dispatch(setSidebar(sidebar));
  };

  // TODO Implement calling toggles on GUIDER-580
  const isDisabled = true;

  if (isDisabled) return <></>;

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        gap: theme.spacing(2),
      })}
    >
      <NavBarButton
        onClick={() => {
          trackClick('Schedule Session Button');
          handleSidebar('session');
        }}
        icon={<AiTwotoneCalendar />}
        label={scheduleSessionLabel}
        data-cy={'schedule-session-toggle-button'}
      />
      <NavBarButton
        onClick={() => {
          trackClick('Goals Button');
          handleSidebar('goal');
        }}
        icon={<AiFillFlag />}
        label={goalsLabel}
        data-cy={'goals-toggle-button'}
      />
      <NavBarButton
        onClick={() => {
          trackClick('Chat Button');
          handleSidebar('chat');
        }}
        icon={<BsFillChatLeftTextFill />}
        label={chatLabel}
        data-cy={'chat-toggle-button'}
      />
    </Box>
  );
};

export default InCallNavBarButtons;
