import { useEffect, useState } from 'react';
import { getSubDomain } from '@guider-global/front-end-utils';
import { IRecipients, getRecipients } from 'utils/getRelationshipRecipients';
import { useProfiles, useRelationships } from 'hooks';
import { GuideAvatar } from 'components/Guide';

export interface AvatarProps {
  relationshipId: string | undefined;
  isGuide: boolean;
}

export function SurveyAvatar({
  relationshipId,
  isGuide,
}: Readonly<AvatarProps>) {
  const organizationSlug = getSubDomain();

  // Local state
  const [fetchedRelationshipProfiles, setFetchedRelationshipProfiles] =
    useState(false);
  const [otherPartyProfile, setOtherPartyProfile] = useState<IRecipients>();

  // Profiles
  const { getProfiles, isLoadingProfiles: getIsLoadingProfiles } = useProfiles(
    {},
  );
  const isLoadingProfiles = getIsLoadingProfiles();
  const userId = getProfiles().at(0)?.userId;

  // Relationship profiles
  const {
    relationships: getRelationships,
    reqRelationships,
    isLoadingRelationships: getIsLoadingRelationships,
  } = useRelationships({});
  const isLoadingRelationships = getIsLoadingRelationships();
  const relationships = getRelationships();

  useEffect(() => {
    if (
      isLoadingProfiles ||
      isLoadingRelationships ||
      !userId ||
      fetchedRelationshipProfiles
    ) {
      return;
    }

    const fetchRelationshipProfiles = async () => {
      await reqRelationships({ url: '/relationships' });
      setFetchedRelationshipProfiles(true);
    };

    fetchRelationshipProfiles();
  }, [
    reqRelationships,
    organizationSlug,
    isLoadingProfiles,
    isLoadingRelationships,
    userId,
    fetchedRelationshipProfiles,
  ]);

  useEffect(() => {
    if (isLoadingProfiles || isLoadingRelationships || !relationshipId) {
      return;
    }

    const relationship = relationships.find(
      (relationship) => relationship.id === relationshipId,
    );

    if (!relationship) {
      return;
    }

    const recipients = getRecipients({
      role: isGuide ? 'guide' : 'trainee',
      relationship,
      userId,
    });

    const otherPartyProfile = recipients.at(0);
    setOtherPartyProfile(otherPartyProfile);
  }, [
    isLoadingProfiles,
    isLoadingRelationships,
    relationships,
    userId,
    relationshipId,
    isGuide,
  ]);

  return (
    <GuideAvatar
      sx={{ borderRadius: 1 }}
      profilePicture={otherPartyProfile?.picture}
      userPicture={otherPartyProfile?.userPicture}
      width={64}
      height={64}
    />
  );
}
