import { FC } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Box } from '@mui/material';

import NavBarButton from '../NavBarButton';

interface ExistSessionButtonsProps {
  relationshipId: string;
  exitSessionLabel: string;
}

const ExistSessionButtons: FC<ExistSessionButtonsProps> = ({
  relationshipId,
  exitSessionLabel,
}) => {
  const handleEndSession = () => {
    window.location.href = `${window.location.origin}/relationships/${relationshipId}`;
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        alignContent: 'center',
      }}
    >
      <NavBarButton
        icon={<AiOutlineClose />}
        label={exitSessionLabel}
        onClick={handleEndSession}
        sx={{
          backgroundColor: 'error.main',
          ':hover': {
            backgroundColor: 'error.dark',
          },
        }}
        data-cy={'exit-session-button'}
      />
    </Box>
  );
};

export default ExistSessionButtons;
